var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background-color": "#F2F2F2"
    },
    attrs: {
      "id": "my-info"
    }
  }, [_c('navigation'), _c('div', {
    staticStyle: {
      "width": "100vw",
      "height": "20.9vw",
      "display": "flex",
      "margin-top": "5vw",
      "margin-bottom": "5vw"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "20.9vw",
      "height": "20.9vw",
      "align-self": "center",
      "margin-left": "5vw",
      "float": "left"
    }
  }, [_c('van-icon', {
    attrs: {
      "size": "20.9vw",
      "name": "user-circle-o"
    }
  })], 1), _c('div', {
    staticStyle: {
      "margin": "3vw",
      "align-self": "center",
      "float": "left",
      "font-size": "4.13vw"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.userName))]), _c('br'), _c('div', {
    staticStyle: {
      "height": "6.41vw",
      "position": "relative"
    }
  }, [_vm._v(_vm._s(_vm.userId) + " "), _c('van-button', {
    staticClass: "copy-button",
    staticStyle: {
      "left": "10vw",
      "position": "absolute",
      "top": "-1vw",
      "border": "solid 0.5vw #BBBBBB",
      "background-color": "#FFE9EC",
      "color": "#BD3124",
      "font-size": "3.59vw",
      "margin-left": "14vw",
      "width": "14.1vw",
      "height": "6.41vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "border-radius": "2.56vw"
    },
    on: {
      "click": function ($event) {
        return _vm.copyButton();
      }
    }
  }, [_vm._v("copy")])], 1)])]), _c('van-cell-group', {
    attrs: {
      "inset": ""
    }
  }, [_c('van-cell', {
    staticClass: "my-cell",
    staticStyle: {
      "height": "11.5vw"
    },
    on: {
      "click": function ($event) {
        return _vm.goAddressList();
      }
    }
  }, [_c('div', {
    staticStyle: {
      "float": "left",
      "display": "inline",
      "width": "95%",
      "font-size": "5.13vw"
    }
  }, [_vm._v(" Shipping address ")]), _c('div', {
    staticStyle: {
      "float": "right",
      "display": "inline",
      "width": "5%",
      "font-size": "5.13vw",
      "color": "gray"
    }
  }, [_vm._v(" > ")])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };