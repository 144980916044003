import request from '../util/request'

export function addressDefault() {
  return request({
    url: '/user/address/default',
    method: 'get'
  })
}

export function addressList() {
  return request({
    url: '/user/address/list',
    method: 'get'
  })
}

export function addressAdd(newAddress) {
  return request({
    url: '/user/address/add',
    method: 'post',
    data: newAddress
  })
}

export function addressUpdate(newAddress) {
  return request({
    url: '/user/address/update',
    method: 'put',
    data: newAddress
  })
}

export function addressSetDefault(addressId) {
  return request({
    url: '/user/address/set/default',
    method: 'post',
    data: addressId
  })
}

export function addressDel(addressId) {
  return request({
    url: '/user/address/del',
    method: 'delete',
    data: addressId
  })
}

export function profile() {
  return request({
    url: '/user/profile',
    method: 'get'
  })
}

export function customizeGuide() {
  return request({
    url: '/user/customize-guide',
    method: 'post'
  })
}
