import "core-js/modules/es.array.push.js";
import Navigation from '../common/Navigation.vue';
import { profile } from '../../api/user.js';
import Clipboard from 'clipboard';
import { Toast, Cell, CellGroup, Image as VanImage } from 'vant';
export default {
  name: 'MyInfo',
  components: {
    Navigation,
    [VanImage.name]: VanImage,
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      copyContent1: '',
      changeLineShow: false,
      userName: '',
      userId: ''
    };
  },
  created: async function () {
    this.getUserInfo();
  },
  mounted() {
    // 创建新的剪贴板实例
    this.clipboard = new Clipboard('.copy-button', {
      text: () => this.copyContent // 将内容复制到剪切板
    });
    // 监听复制成功事件
    Toast.setDefaultOptions({
      duration: 2000
    });
    this.clipboard.on('success', () => {
      Toast({
        message: 'Copied'
      });
    });
  },
  destroyed() {
    // 销毁剪贴板实例
    this.clipboard.destroy();
  },
  methods: {
    goToAboutUs() {
      this.$router.push({
        path: '/information/aboutUs'
      });
    },
    copyButton() {
      this.copyContent = this.userId;
      this.clipboard.onClick({
        // 触发复制操作
        target: document.querySelector('.copy-button')
      });
    },
    getUserInfo() {
      profile().then(res => {
        this.userName = res.data.data.user.name;
        this.userId = res.data.data.user.id + '';
      });
    },
    goAddressList() {
      this.$router.push({
        path: '/user/address/list',
        query: {
          backUrl: this.$route.fullPath
        }
      });
    }
  }
};